/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Moment } from "moment";
import type { DayOfWeek, MonthlyScheduleType, ScopeToEnvironmentMapping, TenantProjectEnvironment } from "../..";
export interface GetDeploymentFreezesResponse {
    DeploymentFreezes: GetDeploymentFreezes[];
}
export interface GetDeploymentFreezes {
    Id: string;
    Name: string;
    Start: string;
    End: string;
    ProjectEnvironmentScope: ScopeToEnvironmentMapping;
    TenantProjectEnvironmentScope: TenantProjectEnvironment[];
    RecurringSchedule?: RecurringScheduleResource;
}
export enum RecurringScheduleType {
    OnceDaily = "OnceDaily",
    DaysPerWeek = "DaysPerWeek",
    DaysPerMonth = "DaysPerMonth",
    Annually = "Annually",
    CronExpression = "CronExpression"
}
export enum RecurringScheduleEndType {
    Never = "Never",
    OnDate = "OnDate",
    AfterOccurrences = "AfterOccurrences"
}
export abstract class RecurringScheduleResource {
    Type: RecurringScheduleType = undefined!;
    Unit: number = undefined!;
    EndType?: RecurringScheduleEndType = RecurringScheduleEndType.Never;
    EndOnDate?: Moment;
    EndAfterOccurrences?: number;
}
export class OnceDailyRecurringSchedule extends RecurringScheduleResource {
    constructor() {
        super();
        this.Type = RecurringScheduleType.OnceDaily;
    }
}
export class DaysPerWeekRecurringSchedule extends RecurringScheduleResource {
    DaysOfWeek: DayOfWeek[] = undefined!;
    constructor() {
        super();
        this.Type = RecurringScheduleType.DaysPerWeek;
    }
}
export class DaysPerMonthRecurringSchedule extends RecurringScheduleResource {
    MonthlyScheduleType: MonthlyScheduleType = undefined!;
    DateOfMonth?: string;
    DayNumberOfMonth?: string;
    DayOfWeek?: DayOfWeek = undefined!;
    constructor() {
        super();
        this.Type = RecurringScheduleType.DaysPerMonth;
    }
}
export class AnnuallyRecurringSchedule extends RecurringScheduleResource {
    constructor() {
        super();
        this.Type = RecurringScheduleType.Annually;
    }
}
export class CronExpressionRecurringSchedule extends RecurringScheduleResource {
    CronExpression: string = undefined!;
    constructor() {
        super();
        this.Type = RecurringScheduleType.CronExpression;
    }
}
