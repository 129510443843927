import type { Client, RequestContext } from "../client";
import type { UseDefaultBranch, BranchSpecifier } from "../repositories/projectRepository";
import type { PagingCollection, GitBranchResource, GitTagResource, GitRef, ValidateGitRefV2Response } from "../resources/index";
export class PlatformHubRepository {
    protected readonly client: Client;
    protected readonly context: RequestContext;
    constructor(client: Client, requestContext: RequestContext) {
        this.client = client;
        this.context = requestContext;
    }
    async tryGetBranch(defaultBranch: string, selectedRef: string): Promise<GitBranchResource | null> {
        type UseDefaultBranch = typeof UseDefaultBranch;
        function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
            return typeof branch === "object";
        }
        try {
            const branchName: string = ShouldUseDefaultBranch(selectedRef) ? defaultBranch : selectedRef;
            return await this.client.get(this.context, "~/bff/platformhub/branches/{/name}", { name: branchName });
        }
        catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }
    getBranches() {
        return this.client.get<PagingCollection<GitBranchResource>>(this.context, "~/bff/platformhub/branches");
    }
    searchBranches(partialBranchName: string): Promise<PagingCollection<GitBranchResource>> {
        return this.client.get(this.context, "~/bff/platformhub/branches{?searchByName}", { searchByName: partialBranchName });
    }
    searchTags(partialBranchName: string): Promise<PagingCollection<GitTagResource>> {
        return this.client.get(this.context, "~/bff/platformhub/tags{?searchByName}", { searchByName: partialBranchName });
    }
    getTags(): Promise<PagingCollection<GitTagResource>> {
        return this.client.get(this.context, "~/bff/platformhub/tags");
    }
    validateGitRef(gitRef: GitRef): Promise<ValidateGitRefV2Response> {
        return this.client.post(this.context, "~/bff/platformhub/validate", { GitRef: gitRef });
    }
    createBranch(newBranchName: string, baseGitRef: GitRef): Promise<GitBranchResource> {
        return this.client.post<GitBranchResource>(this.context, "~/bff/platformhub/branches", { NewBranchName: newBranchName, BaseGitRef: baseGitRef });
    }
}
