import { css } from "@emotion/css";
import { Button, ExternalLink, LinearProgress, resetStyles } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { OctopusRoundTable } from "./OctopusRoundTable";
interface SpaceCallToActionPanelProps {
    callToActionButtonLabel: string;
    onCallToActionRequested: () => void;
    isLoading: boolean;
    errorAlert?: React.ReactNode;
}
export function SpaceCallToActionPanel({ callToActionButtonLabel, onCallToActionRequested, isLoading, errorAlert }: SpaceCallToActionPanelProps) {
    return (<div className={spaceCallToActionPanelStyles}>
            {errorAlert && <div className={headerStyles}>{errorAlert}</div>}
            <div className={contentStyles}>
                <OctopusRoundTable />
                <h3 className={headingStyles}>Create spaces for your teams</h3>
                <p className={copyStyles}>Spaces allow teams to group their projects, infrastructure, tenants, and library assets</p>
                <Button label={callToActionButtonLabel} importance="primary" onClick={onCallToActionRequested}/>
            </div>
            <div className={footerStyles}>
                <span className={learnMoreStyles}>
                    {"Learn more about "}
                    <ExternalLink href="spaces" label="Spaces"/>
                </span>
            </div>
            <LinearProgress variant={"indeterminate"} show={isLoading}/>
        </div>);
}
const spaceCallToActionPanelStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "33.75rem",
});
const headerStyles = css({
    padding: space[16],
});
const contentStyles = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: space[16],
    padding: space[16],
});
const headingStyles = css({
    ...resetStyles.heading,
    color: themeTokens.color.text.primary,
    font: text.heading.large,
});
const copyStyles = css({
    ...resetStyles.paragraph,
    font: text.regular.default.large,
    color: themeTokens.color.text.secondary,
    textAlign: "center",
    maxWidth: "20rem",
});
const footerStyles = css({
    height: "3.25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});
const learnMoreStyles = css({
    font: text.regular.default.medium,
    color: themeTokens.color.text.primary,
});
