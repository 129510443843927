import type { Client, RequestContext } from "../client";
import { Repository } from "../repository";
import type { ProcessTemplateSummary, BlueprintResource, DeleteProcessTemplateCommand, ModifyProcessTemplateCommand, GitRef, NewProcessTemplate, CreateProcessTemplateVersionBffCommand, ProcessTemplateVersionResource } from "../resources";
export class BlueprintRepository {
    protected readonly client: Client;
    protected readonly context: RequestContext;
    constructor(client: Client, requestContext: RequestContext) {
        this.client = client;
        this.context = requestContext;
    }
    all(gitRef: string) {
        return this.client.get<BlueprintResource[]>(this.context, "~/bff/{gitRef}/processtemplates/all", { gitRef });
    }
    getPublishedVersions() {
        return this.client.get<ProcessTemplateVersionResource[]>(this.context, "~/bff/spaces/{spaceId}/processtemplates/versions");
    }
    get(slug: string, gitRef: string | undefined) {
        return this.client.get<BlueprintResource>(this.context, "~/bff/{gitRef}/processtemplates/{slug}", { slug, gitRef });
    }
    del(blueprint: DeleteProcessTemplateCommand) {
        return this.client.post(this.context, "~/bff/processtemplates/delete-by-id", blueprint);
    }
    add(processTemplate: NewProcessTemplate, gitRef: GitRef) {
        return this.client.post<BlueprintResource>(this.context, "~/bff/platformhub/{gitRef}/processtemplates", processTemplate, { gitRef });
    }
    modify(blueprint: ModifyProcessTemplateCommand) {
        return this.client.put<BlueprintResource>(this.context, "~/bff/processtemplates/{blueprintId}", blueprint, { blueprintId: blueprint.Id });
    }
    getPaginatedTemplates(processTemplatesPageFilter: GetProcessTemplatesBffRequest): Promise<GetProcessTemplatesBffResponse> {
        return this.client.get<GetProcessTemplatesBffResponse>(this.context, "~/bff/{gitRef}/processtemplates{?skip,take,partialName}", processTemplatesPageFilter);
    }
    publishVersion(publishCommand: CreateProcessTemplateVersionBffCommand) {
        return this.client.post<ProcessTemplateVersionResource>(this.context, "~/bff/platformhub/processtemplates/publish", publishCommand);
    }
    getVersions(slug: string) {
        return this.client.get<ProcessTemplateVersionResource[]>(this.context, "~/bff/platformhub/processtemplates/{slug}/versions{?skip,take}", { slug, skip: 0, take: Repository.takeAll });
    }
}
export type GetProcessTemplatesBffRequest = {
    skip: number;
    take: number;
    partialName?: string;
    gitRef: string;
};
export interface GetProcessTemplatesBffResponse {
    ProcessTemplates: ProcessTemplateSummary[];
    TotalResults: number;
    ItemsPerPage: number;
    TotalNoOfProcessTemplates: number;
}
